import React from "react";
import Linkify from "react-linkify";
import { INSTRUCTORS } from "../../Constants/EventsConstants/EventsConstants";
import "./styles/Instructor.css";
import linkedin_icon from "../../Resources/Images/linkedinLogo.png";
import gmail_icon from "../../Resources/Images/gmail-logo.png";

const Instructor = (props) => {
    const event_details = props.event_details;

    return (
        <div className="instructors-wrapper">
            <h3>Meet Your Instructor</h3>
            <div className="instructors-content-image-wrapper">
                <div className="instructors-img-wrapper">
                    <img src={INSTRUCTORS[event_details.speakers[0].id].image} alt="" />
                    <div className="instructor-details">
                        <div className="instructor-name-and-links">
                            <h4>{event_details.speakers[0].name}</h4>
                            <a href={INSTRUCTORS[event_details.speakers[0].id].linkedIn}>
                                <img className="instructor-ln" src={linkedin_icon} alt="" />
                            </a>
                            <a href={INSTRUCTORS[event_details.speakers[0].id].gmail}>
                                <img className="instructor-gmail" src={gmail_icon} alt="" />
                            </a>
                        </div>
                        <div className="instructors-job-details-wrapper">
                            {event_details.speakers[0].description}
                        </div>
                    </div>
                </div>
                <div className="instructors-content-wrapper">
                    <Linkify>
                        <div
                            className="instructors-job-desc"
                            dangerouslySetInnerHTML={{ __html: event_details.speakers[0].about }}
                        ></div>
                    </Linkify>
                    {/* <p className="instructors-job-desc">{event_details.speakers[0].about}</p> */}
                </div>
            </div>
        </div>
    );
};

export default Instructor;
