import dummyImg from "../../Resources/Images/DummyTestimonalImg.png";
import HarshitImg from "../../Resources/Images/harshit-story.png";
import turvo from "../../Resources/Images/turvo-story.png";
import BalvinderImg from "../../Resources/Images/balvinder-story.png";
import paloalto from "../../Resources/Images/paloalto-story.png";
import SrikantImg from "../../Resources/Images/srikant-story.png";
import amazon from "../../Resources/Images/amazon-story.png";
import officalIllustrator from "../../Resources/Images/officalIlustrator.svg";
import shareableIllustrator from "../../Resources/Images/shareableIllustrator.svg";
import credibilityIllustrator from "../../Resources/Images/credibilityIllustrator.svg";
import AshutoshImg from "../../Resources/Images/ashutosh-story.png"
import paypal from "../../Resources/Images/paypal-story.png"

const videoTestimonialCardData = [
    {
        name: "Mohit",
        videoId: "dB4SHtXVrOM",
    },
    {
        name: "Anudeep",
        videoId: "IKUmvqY3mUU",
    },
    {
        name: "Jalaj",
        videoId: "ik0QzjtI0AM",
    },
    {
        name: "Saket",
        videoId: "K0hcxryLkiE",
    },
    {
        name: "Shivanshu",
        videoId: "WQiCX5KwA-4",
    },
    {
        name: "Ayush",
        videoId: "JM0Y52NYU2M",
    },
];

const textTestimonialCardData = [
    {
        name: "Harshit Jaiswal",
        img: HarshitImg,
        company_logo: turvo,
        company_logo_wrapper: "amazon-wrapper",
        job_description: "SDE II ",
        link: "https://www.linkedin.com/in/harshitt07/",
        text: (
            <p>
                Programming Pathshala is the best course. You don&apos;t know what you get until you
                enrol and start preparing. It is the most Value for money course available online
                and You’re getting &ldquo;Gold in the price of Silver&rdquo;.
            </p>
        ),
    },
    {
        name: "Balvinder Kaur",
        img: BalvinderImg,
        company_logo: paloalto,
        company_logo_wrapper: "amazon-wrapper",
        job_description: "SDE ",
        link: "https://www.linkedin.com/in/balvinderkaur17/",
        text: "The course covers everything in depth and in a very structured way. There is never enough to learn and grow. I felt very prepared for my interviews after being a part of this course, and could enhance my DSA and problem solving skills in greater depth.",
    } ,{
        name: "Ashutosh Amrutkar",
        img: AshutoshImg,
        company_logo: paypal,
        company_logo_wrapper: "amazon-wrapper",
        job_description: "SDE ",
        link: "https://www.linkedin.com/in/ashutosh-amrutkar-0b15b5169/",
        text: "The course has well-structured lectures, weekend live classes, and comprehensive problems, covered all possible variations. There was constant support from Vivek Sir, Bharat Sir and Anoop Sir who were always accessible, and were a source of inspiration throughout.",
    },
    {
        name: "Srikant Mothe",
        img: SrikantImg,
        company_logo: amazon,
        company_logo_wrapper: "amazon-wrapper",
        job_description: "SDE ",
        link: "https://www.linkedin.com/",
        text: "Both Vivek Sir and Bharat Sir are excellent teachers and under their guidance, I developed my own intuition to approach any new problem of Data Structures and Algorithms. They taught each topic very elaborately and built the concept from very fundamentals.",
    },
];

const getCertificateContents = [
    {
        image: officalIllustrator,
        title: "Official and Verified",
    },
    {
        image: shareableIllustrator,
        title: "Easily Shareable",
    },
    {
        image: credibilityIllustrator,
        title: "Enhances Credibility",
    },
];

export { videoTestimonialCardData, textTestimonialCardData, getCertificateContents };
