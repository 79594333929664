const eventFaqConstants = [
    {
        id: 1,
        question: "Are all events live or recorded ?",
        answer: "All the events will be live and recordings for the same will also be provided to you, in case you miss to attend it Live.",
    },
    {
        id: 2,
        question: "Will I receive a certificate ?",
        answer: "Yes, you will receive a certificate at the end of the event.",
    },
    {
        id: 3,
        question: "Who is the speaker of this event ?",
        answer: "The speaker/teacher would be mentioned above in this page. All our speakers are from top universities like IIT, etc. and top companies like Amazon, Tower Research Capital, etc. ",
    },
    {
        id: 4,
        question: "Where will I receive the link of the session(s) ?",
        answer: "You will receive it on the Email-ID and WhatsApp phone number you register with.",
    },
    {
        id: 5,
        question: "What is the language of teaching ?",
        answer: "It will be in English. ",
    },
    {
        id: 6,
        question: "Can I enrol for more than one event ?",
        answer: "Yes, of course!",
    },
];

const summerFaqConstants = [
    {
        id: 1,
        question: "Are all Summer Courses live or recorded ?",
        answer: "All these Summer Courses will have recorded lectures along with access to our dashboard, where you will have a full fledged learning experience.",
    },
    {
        id: 2,
        question: "Will I receive a certificate ?",
        answer: "Yes, you will receive a certificate at the end of the event.",
    },
    {
        id: 3,
        question: "Who is the speaker of this event ?",
        answer: "The speaker/teacher would be mentioned above in this page. All our speakers are from top universities like IIT, etc. and top companies like Amazon, Tower Research Capital, etc. ",
    },
    {
        id: 4,
        question: "Where will I receive the link of the session(s) ?",
        answer: "You will receive it on the Email-ID and WhatsApp phone number you register with.",
    },
    {
        id: 5,
        question: "What is the language of teaching ?",
        answer: "It will be in English. ",
    },
    {
        id: 6,
        question: "Can I enrol for more than one event ?",
        answer: "Yes, of course!",
    },
];

export { eventFaqConstants, summerFaqConstants };
