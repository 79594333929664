import Vivek from "../../Resources/Images/vivek_event.png";
import Bharat from "../../Resources/Images/bharat_event.png";
import Anoop from "../../Resources/Images/Anoop.png";
import Shreyash from "../../Resources/Images/shreyash-image.png";
import Utkarsh from "../../Resources/Images/utkarsh_event.png";

import vivek_details_image from "../../Resources/Images/vivek_details_image.png";
import bharat_details_image from "../../Resources/Images/bharat_details_image.png";
import anoop_details_image from "../../Resources/Images/anoop_details_image.png";
import utkarsh_details_image from "../../Resources/Images/utkarsh_details_image.png";
import shreyash_details_image from "../../Resources/Images/shreyash_details_image.png";
const INSTRUCTORS = {
    1: {
        image: Vivek,
        details_logo: vivek_details_image,
        linkedIn: "https://www.linkedin.com/in/vivekanand-vivek-7a4ab388",
        gmail: "mailto:vivek@programmingpathshala.com",
    },
    2: {
        image: Bharat,
        details_logo: bharat_details_image,
        linkedIn: "",
        gmail: "",
    },
    3: {
        image: Shreyash,
        details_logo: shreyash_details_image,
        linkedIn: "",
        gmail: "",
    },
    4: {
        image: Anoop,
        details_logo: anoop_details_image,
        linkedIn: "",
        gmail: "",
    },
    5: {
        image: Utkarsh,
        details_logo: utkarsh_details_image,
        linkedIn: "",
        gmail: "",
    },
};

const INSTRUCTORS_LINKS = [
    {
        "Vivekanand Vivek": {},
    },
];

const MINUTES_IN_AN_HOUR = 60;

const SECONDS_IN_A_MINUTE = 60;

const MILISECONDS_IN_A_SECOND = 1000;

const HOURS_IN_A_DAY = 24;

const ZERO_MILISECONDS = 0;

const EVENT_CARDS_PER_PAGE = 4;

const MOBILE_SCREEN_WIDTH = 660;

const PPA_SUPPORT_EMAIL = "support@programmingpathshala.com";

const PPA_CONTACT_NUMBER = "+91 98717 23495";

const PHONE_NUMBER_REGEX = /^[0-9]{10}$/;

const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const EVENT_CATEGORIES = {
    SUMMER_EVENT: "summerCourses",
    FREE_EVENT: "freeEvents",
};

const EVENT_TYPES = {
    FREE: "free",
    PAID: "paid",
};

const SUMMER_EVENT_IDS = [49, 50, 51, 52, 53, 54, 67];
const LPU_EVENT_IDS = [61, 62, 63, 64, 65, 66, 68];

const LPU_EVENT_ID_MAP = {
    49: 61,
    50: 62,
    51: 63,
    52: 64,
    53: 65,
    54: 66,
    67: 68,
};

const COUPON_CODES = {
    LPU: "LPU2023",
};

const PPA_URL = "https://events.programmingpathshala.com/";

const PROFESSION = {
    WORKING_PROFESIONAL: "Working Professional",
    STUDENT: "Student",
};

const EVENT_TAG = {
    LOW_LEVEL_DESIGN: "Low Level Design",
};

const RUPEE_SYMBOL = "\u20B9";

const DEFAULT_PARTICIPANTS = 100;
const WHATS_APP_LINK = "https://wa.me/";
const PPA_ADMIN_PHONE_NUMBER = 919871723495;
const ADMIN_WHATSAPP_MESSAGE =
    "?text=Hi,%20I'm%20interested%20in%20Renaissance.%20But%20I%20have%20some%20queries.";

export {
    MINUTES_IN_AN_HOUR,
    SECONDS_IN_A_MINUTE,
    HOURS_IN_A_DAY,
    MILISECONDS_IN_A_SECOND,
    ZERO_MILISECONDS,
    EVENT_CARDS_PER_PAGE,
    MOBILE_SCREEN_WIDTH,
    PPA_CONTACT_NUMBER,
    PPA_SUPPORT_EMAIL,
    PHONE_NUMBER_REGEX,
    EMAIL_REGEX,
    EVENT_CATEGORIES,
    PPA_URL,
    EVENT_TYPES,
    LPU_EVENT_ID_MAP,
    SUMMER_EVENT_IDS,
    LPU_EVENT_IDS,
    COUPON_CODES,
    INSTRUCTORS,
    PROFESSION,
    EVENT_TAG,
    RUPEE_SYMBOL,
    INSTRUCTORS_LINKS,
    DEFAULT_PARTICIPANTS,
    WHATS_APP_LINK,
    PPA_ADMIN_PHONE_NUMBER,
    ADMIN_WHATSAPP_MESSAGE,
};
