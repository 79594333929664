import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    setUTMParametersInLocalStorage,
    useQuery,
} from "../../../../Utilities/CommonUtility/CommonUtility";
import EventFaq from "../../../EventFaq/EventFaq";
import ExploreEvents from "../../../ExploreEvents/ExploreEvents";
import Footer from "../../../Footer/Footer";
import Header from "../../../Header/Header";
import LearningOutcomes from "../../../LearningOutcomes/LearningOutcomes";
import Certificate from "../../../TestimonialsAndCertificate/Certificate";
import Testimonials from "../../../TestimonialsAndCertificate/Testimonials";
import WhoIsItFor from "../../../WhoIsItFor/WhoIsItFor";
import AboutEvent from "../AboutEvent/AboutEvent";
import getEventDetailsData from "./EventDetailsRouteClient";
import "./styles/EventDetailsRoute.css";

import useWindowDimensions from "../../../../Hooks/UseWindowDimensions";
import RepublicDayBanner from "../../../Banners/RepublicDayBanner";
import WorkshopBenefitForCareer from "../../../workshopBenefitForCareer/WorkshopBenefitForCareer";
import Curriculum from "../../Curriculum/Curriculum";
import Intro from "../../../Intro/Intro";
import Community from "../../../Community/Community";
import Pricing from "../../../Pricing/Pricing";
import Instructor from "../../../Instructor/Instructor";
import TalkToUsButton from "../../../Common/TalkToUsButton";
import EventCompaniesMobileView from "../../EventCompaniesMobileView/EventCompaniesMobileView";

function EventDetailsRoute() {
    const [isLoading, setLoading] = useState(true);
    const {windowWidth} = useWindowDimensions();
    const [EventDetails, setEventDetails] = useState({});
    const { event_id } = useParams();
    const query = useQuery();
    setUTMParametersInLocalStorage(query);

    useEffect(() => {
        window.scrollTo({ top: 0 });
        getEventDetailsData(event_id, setEventDetails, setLoading);
        return () => setEventDetails({});
    }, [event_id]);

    if (isLoading) {
        return (
            <div className="spinner">
                <CircularProgress />
            </div>
        );
    }

    return (
        <section className="eventdetails-wrapper">
            <div>{/* <RepublicDayBanner /> */}</div>
            <div className="header-sticky">
                <Header isEventsPage={true} event_details={EventDetails} />
            </div>
            <Intro event_details={EventDetails} />
            {windowWidth<=600 && <EventCompaniesMobileView event_details={EventDetails}/>}
            <WhoIsItFor event_details={EventDetails} />
            <LearningOutcomes event_details={EventDetails} />
            <Instructor event_details={EventDetails} />
            <AboutEvent event_details={EventDetails} />
            <Testimonials />
            <Curriculum event_details={EventDetails} />
            <WorkshopBenefitForCareer event_details={EventDetails} />
            <Pricing event_details={EventDetails} />
            {EventDetails.event_type === "paid" && <Certificate />}
            <Community />
            <EventFaq event_details={EventDetails} />
            <ExploreEvents event_details={EventDetails} />
            <Footer event_id={EventDetails.event_id} />
            <div>
                <TalkToUsButton />
            </div>
        </section>
    );
}

export default EventDetailsRoute;
