import React from "react";
//import AnnoucementEmoji from "./../../img/annoucement.png";
import "./styles/BannerRibbon.css";
import { HashLink as Link } from "react-router-hash-link";

const BannerRibbon = () => {
    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -80;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
    };
    return (
        <div className="ribbon ">
            <div className="banner-ribbon-wrapper">
                {/* <img src={AnnoucementEmoji} alt="" /> */}
                <p className="announcement-content">
                    Excel in your Coding skills! You can now explore other courses at Programming
                    Pathshala!
                    <Link
                        smooth
                        className="explore-course-link"
                        to="#explore-courses"
                        scroll={(el) => scrollWithOffset(el)}
                    >
                        Click here to Explore!
                    </Link>{" "}
                </p>
                {/* <img src={AnnoucementEmoji} alt="" /> */}
            </div>
        </div>
    );
};

export default BannerRibbon;
