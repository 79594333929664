import React from "react";
import "./styles/WhoIsItFor.css";
import ArrowImg from "../../Resources/Images/whoIsItForArrow.svg";

const WhoIsItFor = (props) => {
    const eventTargetedFor = props.event_details.event_targeted_for;

    return (
        <div className="who-is-it-for-wrapper">
            <div>
                <h3 className="who-is-it-for-heading-2">Who is it for?</h3>

                <div className="type-of-audience-wrapper">
                    {eventTargetedFor &&
                        eventTargetedFor.map((event_targeted_for) => (
                            <div className="type-of-audience-card">
                                <div className="audience-card-tick-mark">
                                    <img src={ArrowImg} alt="" />
                                </div>
                                <p>{event_targeted_for}</p>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default WhoIsItFor;
