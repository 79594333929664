import React from "react";
import Companies_Images from "../../../Resources/Images/Events_Page_Companies_Frame.png";
import "./styles/EventCompaniesMobileView.css";

const EventCompaniesMobileView = (props) => {
    const event_details = props.event_details;
    return (
        <div className="mobile-events-wrapper">
            {event_details.event_companies && (
                <div className="companies-using-springboot">
                    <p>{event_details.event_companies.topic}</p>
                    <div className="companies-logo-many-more">
                        <div className="event-company-images-wrapper">
                            <img src={Companies_Images} alt="companies_images" />
                        </div>
                        <p className="many-more-text">Many More</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EventCompaniesMobileView;
